import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  CircularProgress,
  MenuItem,
  Alert,
  FormHelperText,
} from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import CustomerPaymentMethod from "./CustomerPaymentMethod";
import PaymentMethodDialog from "../Dialogs/PaymentMethodDialog";
import Select from "../../../../ReactHookForm/Select";
import RegisteredTextField from "../../../../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../../../../constants/validationRules";
import ControllerTextField from "../../../../ReactHookForm/ControllerTextField";
import { convertToInt } from "../../../../../utils/dineroFormatters";
import { SUBSCRIPTION_STATUSES } from "../../../../../constants/global";

const PaymentDetails = ({
  subscription,
  customer,
  processors,
  customerPaymentMethod,
  setCustomerPaymentMethod,
  mode,
  isAutoCharged,
  setIsAutoCharged,
  showErrorMessage,
  onCreateCustomerPaymentMethod,
  loading,
}) => {
  const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(false);
  const methods = useFormContext();
  const cardProcessors = processors.filter(
    (p) =>
      p.processorType.type === "CreditCard" &&
      !p.isDeleted &&
      p.processorType.certificationCategory === "VirtualTerminal",
  );

  const achProcessors = processors.filter(
    (p) => p.processorType.type === "Ach" && !p.isDeleted,
  );

  const invoiceLengthInDays = useWatch({
    control: methods.control,
    name: "recurrence.invoiceLengthInDays",
    defaultValue:
      subscription &&
      ![1, 15, 30, 60, 90].includes(
        subscription?.recurrence.invoiceLengthInDays,
      )
        ? -1
        : subscription?.recurrence.invoiceLengthInDays || 30,
  });

  const handleSave = async (paymentMethodId, token, add) => {
    if (paymentMethodId) {
      setCustomerPaymentMethod(
        customer.paymentMethods.find(
          (pm) => pm.paymentMethodId === paymentMethodId,
        ),
      );
    } else {
      onCreateCustomerPaymentMethod(token, add);
    }

    setPaymentMethodsOpen(false);
  };

  useEffect(() => {
    if (subscription === null && mode === "create") {
      methods.setValue("recurrence.invoiceLengthInDays", 30);
    } else if (subscription !== null && mode !== "create") {
      methods.setValue(
        "recurrence.invoiceLengthInDays",
        subscription.recurrence.invoiceLengthInDays,
      );
    }
  }, [subscription]);

  return (
    <Grid container marginTop={2}>
      <Grid item marginY={2}>
        <Typography variant="h3" className="payment-details-heading">
          <strong>Payment Details</strong>
          {loading && <CircularProgress className="loading-icon" size={15} />}
        </Typography>
        <Typography variant="body1">
          Choose whether to automatically charge the customer&apos;s saved
          payment method or send them the invoice for manual payment at the
          start of each billing period.
        </Typography>
      </Grid>
      <Grid
        item
        container
        className="subscription-details"
        rowSpacing={2.5}
        columnSpacing={1}
      >
        <Grid item xs={12} className="payment-details">
          <Typography variant="body1">
            <strong>Choose how to pay:</strong>
          </Typography>
          <FormControlLabel
            label="Automatically charge the payment method on file."
            labelPlacement="end"
            checked={isAutoCharged}
            onChange={() => setIsAutoCharged(true)}
            control={<Radio />}
          />
        </Grid>
        {isAutoCharged && customer && customerPaymentMethod && (
          <Grid item xs={12} className="payment-details payment-method">
            <CustomerPaymentMethod
              paymentMethod={customerPaymentMethod}
              loading={loading}
            />
          </Grid>
        )}
        {isAutoCharged && customer && (
          <Grid item xs={12} className="payment-details payment-method">
            <Button variant="text" onClick={() => setPaymentMethodsOpen(true)}>
              {customerPaymentMethod
                ? "Change Payment Method"
                : "Add Payment Method"}
            </Button>
            {Boolean(
              methods.formState.errors?.paymentMethod?.customerPaymentMethodId,
            ) && (
              <FormHelperText error>
                {
                  methods.formState.errors?.paymentMethod
                    ?.customerPaymentMethodId?.message
                }
              </FormHelperText>
            )}
            <Typography variant="body1">
              If the automatic payment fails, an invoice will be sent for manual
              payment.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} className="payment-details">
          <FormControlLabel
            label="Email the customer an invoice to pay manually."
            labelPlacement="end"
            checked={!isAutoCharged}
            onChange={() => setIsAutoCharged(false)}
            control={<Radio />}
          />
        </Grid>
        <Grid item container spacing={1}>
          {cardProcessors.length > 0 && (
            <Grid item xs={12} sm={6}>
              <Grid item className="processor-select-container">
                {cardProcessors.length > 1 ? (
                  <Select
                    v2
                    control={methods.control}
                    label="Card Processor"
                    name="paymentMethod.cardProcessorId"
                    fullWidth
                    rules={{
                      required: true,
                    }}
                    viewOnly={mode === "view"}
                  >
                    {cardProcessors.map((processor) => (
                      <MenuItem
                        key={processor.processorId}
                        value={processor.processorId}
                      >
                        {processor.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Typography variant="body1">
                    <strong>
                      {achProcessors.length !== 0 && "Card "}Processor:{" "}
                      {cardProcessors[0].name}
                    </strong>
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            {achProcessors.length > 0 && (
              <Grid
                item
                xs={12}
                marginBottom={2}
                className="processor-select-container"
              >
                {achProcessors.length > 1 ? (
                  <Select
                    v2
                    control={methods.control}
                    label="Ach Processor"
                    name="paymentMethod.achProcessorId"
                    fullWidth
                    rules={{
                      required: true,
                    }}
                    viewOnly={mode === "view"}
                  >
                    {achProcessors.map((processor) => (
                      <MenuItem
                        key={processor.processorId}
                        value={processor.processorId}
                        disabled={
                          !processor.features?.availableSecCodes?.some(
                            (s) => s.toLowerCase() === "web",
                          )
                        }
                      >
                        {processor.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : achProcessors[0].features?.availableSecCodes?.some(
                    (s) => s.toLowerCase() === "web",
                  ) ? (
                  <Typography variant="body1">
                    <strong>
                      {cardProcessors.length !== 0 && "ACH "}Processor:{" "}
                      {achProcessors[0].name}
                    </strong>
                  </Typography>
                ) : (
                  <Alert severity="warning" className="no-ach-processor">
                    ACH payments unavailable: No processor configured with WEB
                    SEC code
                  </Alert>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <RegisteredTextField
              v2
              name="prefix"
              label="Invoice Number Prefix"
              fullWidth
              defaultValue={subscription?.prefix}
              rules={{
                maxLength: validationRules.maxLength10,
                required: "Invoice Number Prefix is required",
              }}
              disabled={
                mode === "edit" &&
                subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
              }
            />
            <Typography variant="body1">
              Add a prefix to all invoices generated by this subscription.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} marginTop={-1.25}>
            <Select
              v2
              control={methods.control}
              label="Invoice Terms"
              name="recurrence.invoiceLengthInDays"
              required
              fullWidth
              disabled={
                mode === "edit" &&
                subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
              }
              defaultValue={
                subscription &&
                ![1, 15, 30, 60, 90].includes(
                  subscription?.recurrence.invoiceLengthInDays,
                )
                  ? -1
                  : subscription?.recurrence.invoiceLengthInDays || 30
              }
              data-cy="terms"
              rules={{
                valueAsNumber: true,
              }}
            >
              <MenuItem value={1}>Due On Receipt</MenuItem>
              <MenuItem value={15}>Net 15</MenuItem>
              <MenuItem value={30}>Net 30</MenuItem>
              <MenuItem value={60}>Net 60</MenuItem>
              <MenuItem value={90}>Net 90</MenuItem>
              <MenuItem value={-1}>Custom</MenuItem>
            </Select>
            {invoiceLengthInDays === -1 && (
              <Grid item xs={12} paddingTop={3}>
                <ControllerTextField
                  v2
                  control={methods.control}
                  label="Payment Due"
                  name="recurrence.paymentDue"
                  defaultValue={
                    subscription?.recurrence?.invoiceLengthInDays || 1
                  }
                  inputProps={{ min: 1 }}
                  fullWidth
                  disabled={
                    mode === "edit" &&
                    subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
                  }
                  type="number"
                  rules={{
                    required: true,
                  }}
                  transform={{
                    input: (value) => convertToInt(value, 1),
                    output: (value) => convertToInt(value, 1),
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} marginTop={1.5}>
            <RegisteredTextField
              v2
              name="message"
              label="Message"
              className="invoice-message"
              fullWidth
              defaultValue={subscription?.message}
              multiline
              rows={3}
              rules={{
                maxLength: validationRules.maxLength500,
              }}
              disabled={
                mode === "edit" &&
                subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
              }
            />
            <Typography variant="body1">
              This message will display on all invoices.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <PaymentMethodDialog
        open={paymentMethodsOpen}
        onClose={() => setPaymentMethodsOpen(false)}
        onSave={handleSave}
        paymentMethods={customer?.paymentMethods}
        defaultPaymentMethod={customerPaymentMethod}
        showErrorMessage={showErrorMessage}
        showCard={cardProcessors?.length > 0}
        showAch={achProcessors?.length > 0}
      />
    </Grid>
  );
};

PaymentDetails.propTypes = {
  subscription: PropTypes.object,
  customer: PropTypes.object,
  processors: PropTypes.arrayOf(PropTypes.object),
  mode: PropTypes.oneOf(["view", "edit", "create"]),
  isAutoCharged: PropTypes.bool,
  setIsAutoCharged: PropTypes.func,
  customerPaymentMethod: PropTypes.object,
  setCustomerPaymentMethod: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onCreateCustomerPaymentMethod: PropTypes.func,
  loading: PropTypes.bool,
};

export default PaymentDetails;
