import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Collapse, MenuItem, TextField } from "@mui/material";
import Select from "../../../../ReactHookForm/Select";
import { useFormContext, useWatch } from "react-hook-form";
import { formatDate } from "../../../InvoicesCreate/StepTwo/InvoiceStepTwo";
import ControllerTextField from "../../../../ReactHookForm/ControllerTextField";
import { convertToInt } from "../../../../../utils/dineroFormatters";
import LabelWithTooltip from "../../../InvoicesCreate/StepTwo/LabelWithTooltip";
import {
  addDaysToDate,
  differenceInDays,
} from "../../../../../utils/dateHelpers";
import Switch from "../../../../ReactHookForm/Switch";
import { parseISO } from "date-fns";
import { getNextDate } from "../subscriptionHelpers";
import { SUBSCRIPTION_STATUSES } from "../../../../../constants/global";
import { useIsInitialRender } from "../../../../../hooks";

const BillingSchedule = ({ subscription, mode, billingPeriods }) => {
  const methods = useFormContext();
  const isInitialRender = useIsInitialRender();
  const [displayAdjustment, setDisplayAdjustment] = useState(false);

  const termStartDate = useWatch({
    control: methods.control,
    name: "recurrence.termStartDate",
    defaultValue: formatDate(new Date().toISOString().split("T")[0]),
  });

  const termEndDate = useWatch({
    control: methods.control,
    name: "recurrence.termEndDate",
    defaultValue: "",
  });

  const trialLengthInDays = useWatch({
    control: methods.control,
    name: "recurrence.trialLengthInDays",
    defaultValue: 0,
  });

  const billingStartDate = useWatch({
    control: methods.control,
    name: "recurrence.billingStartDate",
    defaultValue: formatDate(
      addDaysToDate(termStartDate, trialLengthInDays)
        .toISOString()
        .split("T")[0],
    ),
  });

  const endSubscription = useWatch({
    control: methods.control,
    name: "recurrence.endSubscription",
    defaultValue:
      subscription?.recurrence.numberOfBillingPeriods ||
      subscription?.recurrence.termEndDate
        ? "numPeriods"
        : "never",
  });

  const subscriptionEnds = useWatch({
    control: methods.control,
    name: "recurrence.subscriptionEnds",
    defaultValue: subscription?.recurrence.numberOfBillingPeriods
      ? "billingPeriods"
      : "endDate",
  });

  const billingPeriodId = useWatch({
    control: methods.control,
    name: "recurrence.billingPeriodId",
    defaultValue:
      subscription?.recurrence?.billingPeriod?.billingPeriodId ||
      billingPeriods.find((bp) => bp.name === "Monthly")?.billingPeriodId,
  });

  useEffect(() => {
    // For some reason, these won't reset unless I set them individually
    if (!subscription) {
      methods.setValue("recurrence.isAutoRenewed", "false");
      methods.setValue("recurrence.termEndDate", "");
      methods.setValue("recurrence.endSubscription", "numPeriods");
      methods.setValue("recurrence.subscriptionEnds", "endDate");
      methods.setValue(
        "recurrence.termStartDate",
        formatDate(new Date().toISOString()),
      );
      methods.setValue("recurrence.trialLengthInDays", 0);
      methods.setValue(
        "recurrence.billingStartDate",
        formatDate(
          addDaysToDate(termStartDate, trialLengthInDays).toISOString(),
        ),
      );
      methods.setValue(
        "recurrence.billingPeriodId",
        billingPeriods.find((bp) => bp.name === "Monthly")?.billingPeriodId,
      );
    } else {
      methods.setValue(
        "recurrence.isAutoRenewed",
        subscription.recurrence?.isAutoRenewed.toString() || "false",
      );
      methods.setValue(
        "recurrence.termEndDate",
        formatDate(subscription.recurrence?.termEndDate),
      );
      methods.setValue(
        "recurrence.endSubscription",
        (subscription.recurrence.numberOfBillingPeriods ||
          subscription.recurrence.termEndDate) &&
          subscription.createdDateTime
          ? "numPeriods"
          : "never",
      );
      methods.setValue(
        "recurrence.subscriptionEnds",
        subscription?.recurrence.numberOfBillingPeriods
          ? "billingPeriods"
          : "endDate",
      );
      methods.setValue(
        "recurrence.termStartDate",
        formatDate(subscription.recurrence?.termStartDate),
      );
      methods.setValue(
        "recurrence.trialLengthInDays",
        subscription.recurrence?.trialLengthInDays,
      );
      methods.setValue(
        "recurrence.billingStartDate",
        formatDate(subscription.recurrence?.billingStartDate),
      );
      methods.setValue(
        "recurrence.billingPeriodId",
        subscription.recurrence?.billingPeriod?.billingPeriodId ||
          billingPeriods.find((bp) => bp.name === "Monthly")?.billingPeriodId,
      );
    }
  }, [subscription]);

  useEffect(() => {
    if (!Number.isInteger(trialLengthInDays)) {
      methods.setValue(
        "recurrence.trialLengthInDays",
        convertToInt(trialLengthInDays, 0),
      );
      return;
    }

    if (!isInitialRender)
      methods.setValue(
        "recurrence.billingStartDate",
        addDaysToDate(termStartDate, trialLengthInDays)
          .toISOString()
          .split("T")[0],
      );
  }, [trialLengthInDays]);

  useEffect(() => {
    setDisplayAdjustment(
      differenceInDays(
        formatDate(
          addDaysToDate(termStartDate, trialLengthInDays).toISOString(),
        ),
        billingStartDate,
      ) > 0,
    );

    if (differenceInDays(termStartDate, billingStartDate) < 0)
      methods.setError("recurrence.billingStartDate", {
        type: "min",
        message:
          "Date must be on or after the Start Date and cannot be in the past.",
      });
    else methods.clearErrors("recurrence.billingStartDate");
  }, [termStartDate, billingStartDate, trialLengthInDays]);

  useEffect(() => {
    if (termEndDate && termEndDate !== "") {
      if (
        differenceInDays(termStartDate, termEndDate) <= 0 ||
        differenceInDays(billingStartDate, termEndDate) <= 0
      )
        methods.setError("recurrence.termEndDate", {
          type: "min",
          message:
            "Date must be after the Start Date and cannot be in the past.",
        });
      else methods.clearErrors("recurrence.termEndDate");
    }
  }, [termStartDate, billingStartDate, termEndDate]);

  useEffect(() => {
    if (subscription?.status?.name === SUBSCRIPTION_STATUSES.ACTIVE) return;

    if (
      differenceInDays(formatDate(new Date().toISOString()), termStartDate) < 0
    ) {
      const billingPeriod = billingPeriods.find(
        (bp) => bp.billingPeriodId === billingPeriodId,
      );

      methods.setValue(
        "recurrence.billingStartDate",
        formatDate(
          getNextDate(billingPeriod.name, termStartDate).toISOString(),
        ),
      );
    } else if (displayAdjustment) {
      methods.setValue(
        "recurrence.billingStartDate",
        formatDate(
          addDaysToDate(termStartDate, trialLengthInDays).toISOString(),
        ),
      );
    }
  }, [termStartDate, billingPeriodId]);

  const removeLeadingZero = (input) => {
    if (Number.isInteger(input)) return input;

    return input.replace(/^0/, "");
  };

  return (
    <Grid container marginTop={2}>
      <Grid item marginY={2}>
        <Typography variant="h3">
          <strong>Billing Schedule</strong>
        </Typography>
      </Grid>
      <Grid
        item
        container
        className="subscription-details"
        rowSpacing={2.5}
        columnSpacing={1}
      >
        <Grid item xs={12} sm={6} className="billing-schedule">
          <Select
            v2
            control={methods.control}
            name="recurrence.billingPeriodId"
            label="Billing Period"
            fullWidth
            defaultValue={
              subscription?.recurrence?.billingPeriod?.billingPeriodId ||
              billingPeriods.find((bp) => bp.name === "Monthly")
                ?.billingPeriodId
            }
            rules={{
              required: "Billing Period is required",
            }}
            disabled={
              mode === "edit" &&
              subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
            }
          >
            {billingPeriods.map((bp) => (
              <MenuItem key={bp.billingPeriodId} value={bp.billingPeriodId}>
                {bp.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} className="billing-schedule">
          <Select
            v2
            control={methods.control}
            name="recurrence.endSubscription"
            label="End Subscription"
            fullWidth
            defaultValue="numPeriods"
            rules={{
              required: "End Subscription is required",
            }}
          >
            <MenuItem value="numPeriods">After # of Billing Periods</MenuItem>
            <MenuItem value="never">Never</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="date"
            label="Subscription Starts"
            name="recurrence.termStartDate"
            value={termStartDate}
            onChange={(e) =>
              methods.setValue(
                "recurrence.termStartDate",
                new Date(e.target.value).toISOString().split("T")[0],
              )
            }
            InputLabelProps={{
              shrink: false,
            }}
            fullWidth
            required
            disabled={
              mode === "edit" &&
              subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
            }
            error={Boolean(methods.formState.errors?.recurrence?.termStartDate)}
            helperText={
              methods.formState.errors?.recurrence?.termStartDate?.message
            }
          />
        </Grid>
        <Grid item container xs={12} sm={6} spacing={1}>
          {endSubscription === "numPeriods" && (
            <>
              <Grid item xs={6} id="end-subscription-dropdown">
                <Select
                  v2
                  control={methods.control}
                  name="recurrence.subscriptionEnds"
                  label="Subscription Ends"
                  fullWidth
                  defaultValue={
                    subscription?.recurrence.numberOfBillingPeriods
                      ? "billingPeriods"
                      : "endDate"
                  }
                  rules={{
                    required: "Subscription Ends is required",
                  }}
                  disabled={
                    mode === "edit" &&
                    subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
                  }
                >
                  <MenuItem value="billingPeriods">Billing Periods</MenuItem>
                  <MenuItem value="endDate">End Date</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6} className="subscription-end-details">
                {subscriptionEnds === "endDate" ? (
                  <TextField
                    type="date"
                    name="recurrence.termEndDate"
                    value={termEndDate}
                    onChange={(e) =>
                      methods.setValue(
                        "recurrence.termEndDate",
                        new Date(e.target.value).toISOString().split("T")[0],
                      )
                    }
                    InputLabelProps={{
                      shrink: false,
                    }}
                    fullWidth
                    required
                    error={Boolean(
                      methods.formState.errors?.recurrence?.termEndDate,
                    )}
                    helperText={
                      methods.formState.errors?.recurrence?.termEndDate?.message
                    }
                  />
                ) : (
                  <ControllerTextField
                    control={methods.control}
                    name="recurrence.numberOfBillingPeriods"
                    defaultValue={
                      subscription?.recurrence?.numberOfBillingPeriods || 1
                    }
                    inputProps={{ min: 1 }}
                    fullWidth
                    disabled={
                      mode === "edit" &&
                      subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
                    }
                    type="number"
                    rules={{
                      required: true,
                    }}
                    transform={{
                      input: (value) => convertToInt(value, 1),
                      output: (value) => convertToInt(value, 1),
                    }}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="date"
            label="Billing Starts"
            name="recurrence.billingStartDate"
            value={billingStartDate}
            onChange={(e) =>
              methods.setValue(
                "recurrence.billingStartDate",
                new Date(e.target.value).toISOString().split("T")[0],
              )
            }
            InputLabelProps={{
              shrink: false,
            }}
            fullWidth
            required
            disabled={
              mode === "edit" &&
              subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
            }
            error={Boolean(
              methods.formState.errors?.recurrence?.billingStartDate,
            )}
            helperText={
              methods.formState.errors?.recurrence?.billingStartDate?.message
            }
          />
          <Typography variant="body1">
            This defines the billing anchor date.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} id="auto-renew-dropdown">
          {endSubscription === "numPeriods" && (
            <Select
              v2
              control={methods.control}
              name="recurrence.isAutoRenewed"
              label="At End of Term"
              fullWidth
              defaultValue={
                subscription?.recurrence?.isAutoRenewed.toString() || "false"
              }
              rules={{
                required: "At End of Term is required",
              }}
            >
              <MenuItem value="true">Auto Renew Subscription</MenuItem>
              <MenuItem value="false">Complete Subscription</MenuItem>
            </Select>
          )}
        </Grid>
        <Grid item xs={12} sm={6} id="subscription-trial-days">
          <LabelWithTooltip
            v2
            label="Trial Days *&nbsp;"
            tooltip="A $0 invoice will be created for the time in trial."
            placement="right"
          />
          <ControllerTextField
            v2
            control={methods.control}
            name="recurrence.trialLengthInDays"
            defaultValue={subscription?.recurrence?.trialLengthInDays || 0}
            inputProps={{ min: 0 }}
            fullWidth
            disabled={
              mode === "edit" &&
              subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
            }
            type="number"
            rules={{
              required: true,
            }}
            transform={{
              input: (value) => removeLeadingZero(value),
              output: (value) => removeLeadingZero(value),
            }}
            onFocus={(e) => e.target.select()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Collapse in={displayAdjustment}>
            <Switch
              control={methods.control}
              label="Charge for Adjusted Billing Date"
              labelPlacement="start"
              name="recurrence.allowProration"
              defaultValue={subscription?.recurrence.allowProration || false}
              disabled={
                mode === "edit" &&
                subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
              }
            />
            <Typography
              className="adjusted-invoice-message"
              variant="body1"
            >{`Create an adjusted invoice for ${
              addDaysToDate(termStartDate, trialLengthInDays)
                .toLocaleDateString()
                .split("T")[0]
            } - ${parseISO(
              billingStartDate,
            ).toLocaleDateString()}`}</Typography>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};

BillingSchedule.propTypes = {
  subscription: PropTypes.object,
  mode: PropTypes.oneOf(["view", "edit", "create"]),
  billingPeriods: PropTypes.arrayOf(PropTypes.object),
};

export default BillingSchedule;
