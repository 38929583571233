import React, { useContext, useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import SelectCustomerDrawer from "../../../InvoicesCreate/StepOne/SelectCustomerDrawer";
import { sassEndpoints } from "../../../../../constants/endpoints";
import { UserSettingsContext } from "../../../../../contexts/UserSettingsContext";
import { useHistory } from "react-router";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { merchantRoutes, noProcessors } from "../../../../../constants/routes";
import { stringFormat } from "../../../../../utils/stringHelpers";
import axios from "axios";
import CustomerDetails from "./CustomerDetails";
import SetUp from "./SetUp";
import { statusTag } from "../subscriptionHelpers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STATUS_IDS,
} from "../../../../../constants/global";
import { useAxios } from "../../../../../hooks";
import BillingSchedule from "./BillingSchedule";
import LineItemTotals from "../../../InvoicesCreate/StepThree/LineItemTotals";
import { getDefaultLineItem } from "../../../InvoicesCreate/StepThree/InvoiceStepThree";
import { GatewayConfigContext } from "../../../../../contexts/GatewayConfigContext";
import PaymentDetails from "./PaymentDetails";
import { differenceInDays } from "../../../../../utils/dateHelpers";
import { formatDate } from "../../../InvoicesCreate/StepTwo/InvoiceStepTwo";
import { GatewayContext } from "../../../../../contexts/GatewayContext";
import Dinero from "dinero.js";
import Summary from "./Summary";
import InvoiceTable from "./InvoiceTable";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import CancelDialog from "../Dialogs/CancelDialog";
import ResumeDialog from "../Dialogs/ResumeDialog";
import PreventNavigationDialog from "../../../InvoicesCreate/PreventNavigation/PreventNavigationDialog";

const SubscriptionDetailsContainer = forwardRef(
  (
    {
      mode,
      setMode,
      setFormIsDirty,
      activeSubscription,
      setSubscription,
      billingPeriods,
      showErrorMessage,
      submitting,
      onSave,
      onUpdate,
      onPause,
      onResume,
      onCancel,
      onDelete,
    },
    ref,
  ) => {
    const { userSettings } = useContext(UserSettingsContext);
    const { gatewayConfigSettings } = useContext(GatewayConfigContext);
    const gateway = useContext(GatewayContext);
    const [searchCustomer, setSearchCustomer] = useState(false);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [customerPaymentMethodLoading, setCustomerPaymentMethodLoading] =
      useState(false);
    const [displayBilling, setDisplayBilling] = useState(false);
    const [displayShipping, setDisplayShipping] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [processors, setProcessors] = useState([]);
    const [processorsLoading, setProcessorsLoading] = useState(false);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
    const [saveOptionsAnchorEl, setSaveOptionsAnchorEl] = useState(null);
    const [isAutoCharged, setIsAutoCharged] = useState(
      activeSubscription?.paymentMethod.isAutoCharged || false,
    );
    const [customerPaymentMethod, setCustomerPaymentMethod] = useState(null);
    const [billingAddress, setBillingAddress] = useState(
      activeSubscription?.addresses?.find((a) => a.isBilling) ||
        customer?.addresses?.find((a) => a.isBilling),
    );
    const [shippingAddress, setShippingAddress] = useState(
      activeSubscription?.addresses?.find((a) => a.isShipping) ||
        customer?.addresses?.find((a) => a.isShipping),
    );
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({
      open: false,
    });
    const [openCancel, setOpenCancel] = useState(false);
    const [openResume, setOpenResume] = useState(false);
    const [defaultTotals, setDefaultTotals] = useState({
      subtotal: 0,
      discounts: 0,
      tax: 0,
      total: 0,
      remainingBalance: 0,
    });
    const [billingPeriodName, setBillingPeriodName] = useState(
      activeSubscription?.recurrence?.billingPeriod?.name || "Custom Period",
    );
    const optionsOpen = Boolean(optionsAnchorEl);
    const saveOptionsOpen = Boolean(saveOptionsAnchorEl);
    const history = useHistory();
    const { data: unitsOfMeasure, loading: unitsLoading } = useAxios(
      {
        method: "GET",
        url: sassEndpoints.invoices.unitsOfMeasure,
      },
      { initialData: [], dataTransform: (data) => data.data },
    );
    const defaultLineItem = getDefaultLineItem(
      gatewayConfigSettings,
      unitsOfMeasure,
    );
    const methods = useForm({
      shouldUnregister: true,
      defaultValues: {
        lineItems: activeSubscription?.lineItems?.map((item) => ({
          ...item,
          unitPrice: Math.round(item.unitPrice * 100),
          discount: Math.round(item.discount * 100),
          freightAmount: item.freightAmount
            ? Math.round(item.freightAmount * 100)
            : null,
          shippedAmount: item.shippedAmount || null,
          localTaxPercent: item.isTaxable
            ? Math.round(item.localTaxPercent * 1000)
            : defaultLineItem.localTaxPercent,
          nationalTaxPercent: item.isTaxable
            ? Math.round(item.nationalTaxPercent * 1000)
            : defaultLineItem.nationalTaxPercent,
          advancedFieldsEnabled:
            (item.shippedAmount || null) !== null ||
            (item.freightAmount || null) !== null,
          unitOfMeasureId:
            item.unitOfMeasureId || defaultLineItem.unitOfMeasureId,
        })) || [defaultLineItem],
        recurrence: {
          billingPeriodId:
            activeSubscription?.recurrence?.billingPeriod?.billingPeriodId ||
            billingPeriods.find((bp) => bp.name === "Monthly")?.billingPeriodId,
          endSubscription: activeSubscription
            ? (activeSubscription?.recurrence?.numberOfBillingPeriods ||
                activeSubscription?.recurrence?.termEndDate) &&
              activeSubscription?.createdDateTime
              ? "numPeriods"
              : "never"
            : "numPeriods",
          trialLengthInDays:
            activeSubscription?.recurrence?.trialLengthInDays || 0,
          invoiceLengthInDays:
            activeSubscription?.recurrence?.invoiceLengthInDays || 30,
        },
      },
    });
    const canEditSubscription =
      userSettings.gatewayUserPermissions["EDIT_SUBSCRIPTION"] &&
      activeSubscription?.status?.name !== SUBSCRIPTION_STATUSES.COMPLETE &&
      activeSubscription?.status?.name !== SUBSCRIPTION_STATUSES.CANCELLED;
    const canDeleteSubscription =
      userSettings.gatewayUserPermissions["DELETE_SUBSCRIPTION"] &&
      (activeSubscription?.status?.name === SUBSCRIPTION_STATUSES.DRAFT ||
        activeSubscription?.status?.name === SUBSCRIPTION_STATUSES.SCHEDULED);

    const billingPeriodId = useWatch({
      control: methods.control,
      name: "recurrence.billingPeriodId",
    });

    useEffect(() => {
      loadProcessors();
    }, []);

    useEffect(() => {
      setDisplayBilling(mode === "create");
    }, [mode]);

    useEffect(() => {
      setBillingPeriodName(
        billingPeriods.find((bp) =>
          mode === "view"
            ? bp.billingPeriodId ===
              activeSubscription?.recurrence?.billingPeriod?.billingPeriodId
            : bp.billingPeriodId ===
              (billingPeriodId ||
                activeSubscription?.recurrence?.billingPeriod?.billingPeriodId),
        )?.name,
      );
    }, [activeSubscription, billingPeriodId]);

    useEffect(() => {
      if (sessionStorage) {
        if (sessionStorage.getItem("newSubscription")) {
          const sub = JSON.parse(sessionStorage.getItem("newSubscription"));
          Object.keys(sub).forEach((key) => {
            if (key === "recurrence")
              Object.keys(sub[key]).forEach((k) => {
                if (k === "isAutoRenewed")
                  methods.setValue(`recurrence.${k}`, sub[key][k].toString());
                else methods.setValue(`recurrence.${k}`, sub[key][k]);
              });
            else methods.setValue(key, sub[key]);
          });
          setSubscription(sub);
          sessionStorage.removeItem("newSubscription");
        }

        if (sessionStorage.getItem("customerId")) {
          handleCustomerChange(sessionStorage.getItem("customerId"));
          sessionStorage.removeItem("customerId");
        }
      }
    }, [sessionStorage]);

    useEffect(() => {
      if (sessionStorage && !sessionStorage.getItem("newSubscription")) {
        handleCustomerChange(activeSubscription?.customer?.customerId);
        setIsAutoCharged(
          activeSubscription?.paymentMethod.isAutoCharged || false,
        );

        methods.setValue(
          "lineItems",
          activeSubscription?.lineItems.map((item) => ({
            ...item,
            unitPrice: Math.round(item.unitPrice * 100),
            discount: Math.round(item.discount * 100),
            freightAmount: item.freightAmount
              ? Math.round(item.freightAmount * 100)
              : null,
            shippedAmount: item.shippedAmount || null,
            localTaxPercent: item.isTaxable
              ? Math.round(item.localTaxPercent * 1000)
              : defaultLineItem.localTaxPercent,
            nationalTaxPercent: item.isTaxable
              ? Math.round(item.nationalTaxPercent * 1000)
              : defaultLineItem.nationalTaxPercent,
            advancedFieldsEnabled:
              (item.shippedAmount || null) !== null ||
              (item.freightAmount || null) !== null,
            unitOfMeasureId:
              item.unitOfMeasureId || defaultLineItem.unitOfMeasureId,
          })) || [defaultLineItem],
        );

        if (activeSubscription) {
          methods.setValue("name", activeSubscription.name);
          methods.setValue("prefix", activeSubscription.prefix);
        }
      }

      if (mode === "create") {
        resetFields();
        setIsAutoCharged(true);
      }
    }, [activeSubscription, mode]);

    useEffect(() => {
      if (activeSubscription?.remit)
        setDefaultTotals({
          subtotal: activeSubscription.remit.baseAmount,
          discounts: activeSubscription.remit.discountAmount,
          tax: activeSubscription.remit.taxAmount,
          total: activeSubscription.remit.totalAmount,
          shipping: { amount: activeSubscription.remit.shippingAmount },
          remainingBalance: activeSubscription.remit.totalAmount,
        });
      else
        setDefaultTotals({
          subtotal: 0,
          discounts: 0,
          tax: 0,
          total: 0,
          remainingBalance: 0,
        });

      if (activeSubscription?.addresses) {
        const billing = activeSubscription?.addresses?.find((a) => a.isBilling);
        const shipping = activeSubscription?.addresses?.find(
          (a) => a.isShipping,
        );
        methods.setValue("billing", billing);
        methods.setValue("shipping", shipping);
        setBillingAddress(billing);
        setShippingAddress(shipping);
      }
    }, [activeSubscription]);

    useEffect(() => {
      setFormIsDirty(methods.formState.isDirty);
    }, [methods.formState.isDirty]);

    useEffect(() => {
      if (processors && mode !== "view") {
        const cardProcessors = processors.filter(
          (p) =>
            p.processorType.type === "CreditCard" &&
            !p.isDeleted &&
            p.processorType.certificationCategory === "VirtualTerminal",
        );
        const achProcessors = processors.filter(
          (p) => p.processorType.type === "Ach" && !p.isDeleted,
        );
        if (cardProcessors.length > 1) {
          methods.setValue(
            "paymentMethod.cardProcessorId",
            activeSubscription?.paymentMethod?.cardProcessorId ||
              cardProcessors.find((p) => p.isDefaultCard)?.processorId,
          );
        }
        if (achProcessors.length > 1) {
          methods.setValue(
            "paymentMethod.achProcessorId",
            activeSubscription?.paymentMethod?.achProcessorId ||
              achProcessors.find(
                (p) =>
                  p.isDefaultAch &&
                  p.features?.availableSecCodes?.some(
                    (s) => s.toLowerCase() === "web",
                  ),
              )?.processorId,
          );
        }
      }
    }, [processors, mode]);

    const loadProcessors = () => {
      const url = stringFormat(sassEndpoints.processors.processor, [
        userSettings.gatewayId,
      ]);

      setProcessorsLoading(true);

      axios
        .get(url)
        .then((response) => {
          if (response.status === 204) history.push(noProcessors);
          else {
            setProcessors(response?.data?.data || []);
            setProcessorsLoading(false);
          }
        })
        .catch(() => {
          setProcessorsLoading(false);
          showErrorMessage("Failed to load processor information.");
        });
    };

    const resetFields = () => {
      const values = methods.getValues();
      Object.keys(values).forEach((field) => {
        if (field === "recurrence")
          Object.keys(values[field]).forEach((k) => {
            methods.resetField(`recurrence.${k}`);
          });
        else methods.resetField(field);
      });
    };

    const handleCustomerChange = (customerId, newPaymentMethodId) => {
      if (!customerId) {
        setCustomer(null);
        return;
      }

      const url = stringFormat(sassEndpoints.customers.customerId, [
        userSettings.gatewayId,
        customerId,
      ]);

      setCustomerLoading(true);

      axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            if (!newPaymentMethodId) {
              if (!activeSubscription) {
                setCustomerPaymentMethod(
                  response.data.data.paymentMethods.find((pm) => pm.isDefault),
                );
                setBillingAddress(
                  response.data.data?.addresses?.find((a) => a.isBilling),
                );
                setShippingAddress(
                  response.data.data?.addresses?.find((a) => a.isShipping),
                );
              } else {
                setCustomerPaymentMethod(
                  activeSubscription.paymentMethod.customerPaymentMethod,
                );

                if (
                  customer &&
                  customer.customerId !== response.data.data.customerId
                ) {
                  setBillingAddress(
                    response.data.data?.addresses?.find((a) => a.isBilling),
                  );
                  setShippingAddress(
                    response.data.data?.addresses?.find((a) => a.isShipping),
                  );
                } else {
                  setBillingAddress(
                    activeSubscription?.addresses?.find((a) => a.isBilling),
                  );
                  setShippingAddress(
                    activeSubscription?.addresses?.find((a) => a.isShipping),
                  );
                }
              }
            } else {
              setCustomerPaymentMethod(
                response.data.data.paymentMethods.find(
                  (pm) => pm.paymentMethodId === newPaymentMethodId,
                ),
              );
            }

            setCustomer(response.data.data);
            setCustomerLoading(false);
          }
        })
        .catch(() => {
          setCustomerLoading(false);
          showErrorMessage("Failed to load customer information");
        });

      setSearchCustomer(false);
    };

    const handleCreateCustomer = () => {
      const sub = formatPayload();

      sessionStorage.setItem("newSubscription", JSON.stringify(sub));
      history.push(merchantRoutes.customer.create, { return: true });
    };

    const handleCreatePaymentMethod = (token, add) => {
      setCustomerPaymentMethodLoading(true);

      let payload = {
        [add]: {
          token: token?.token || token,
          ...(token?.expirationDate && {
            expirationDate: token.expirationDate,
            maskedCard: token.maskedCard,
          }),
        },
      };
      let url = `${stringFormat(sassEndpoints.customers.customerId, [
        userSettings.gatewayId,
        customer.customerId,
      ])}/payment`;

      axios
        .post(url, payload)
        .then((resp) => {
          handleCustomerChange(
            customer.customerId,
            resp.data.data.customerPaymentMethodId,
          );
        })
        .catch((error) => {
          showErrorMessage(
            error?.response?.data?.statusDetails?.join(", ") ||
              "Failed to create payment method.",
          );
          setCustomerPaymentMethodLoading(false);
        })
        .finally(() => {
          setCustomerPaymentMethodLoading(false);
          methods.clearErrors("paymentMethod.customerPaymentMethodId");
        });
    };

    const handleMenuClose = () => {
      setOptionsAnchorEl(null);
      setSaveOptionsAnchorEl(null);
    };

    const handleViewAsPDF = (invoiceId) => {
      setInvoiceLoading(true);

      const url = stringFormat(sassEndpoints.invoices.pdf, [
        userSettings.gatewayId,
        invoiceId,
      ]);

      axios
        .get(url, {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch((error) => {
          showErrorMessage(
            // eslint-disable-next-line prettier/prettier
            error.response?.data?.statusDetails.join(", ") ||
              "Failed to retrieve invoice PDF",
          );
          setInvoiceLoading(false);
        })
        .finally(() => {
          setInvoiceLoading(false);
        });
    };

    const toggleEdit = () => {
      setMode("edit");
      handleMenuClose();
    };

    const calculateStatus = (sub) => {
      if (
        differenceInDays(
          formatDate(new Date().toISOString()),
          sub.recurrence.termStartDate,
        ) > 0
      ) {
        return SUBSCRIPTION_STATUS_IDS.SCHEDULED;
      } else if (sub.recurrence.trialLengthInDays > 0) {
        return SUBSCRIPTION_STATUS_IDS.TRIALING;
      } else if (
        activeSubscription?.status?.subscriptionStatusId ===
        SUBSCRIPTION_STATUS_IDS.OVERDUE
      ) {
        return SUBSCRIPTION_STATUS_IDS.OVERDUE;
      } else return SUBSCRIPTION_STATUS_IDS.ACTIVE;
    };

    const formatAddresses = (data) => {
      let addresses = [];
      let types = ["billing", "shipping"];
      types.forEach((type) => {
        let filledAddress = false;

        if (data[type]) {
          Object.entries(data[type]).forEach((val) => {
            if (val[0] !== "country" && val[1]?.length) {
              filledAddress = true;
            }
          });

          if (filledAddress) {
            const details = data[type];
            const address = {
              isPhysical: false,
              isShipping: type === "shipping",
              isBilling: type === "billing",
              firstName: details?.first_name,
              lastName: details?.last_name,
              email: details?.email,
              addressLine1: details?.address_line_1,
              addressLine2: details?.address_line_2,
              city: details?.city,
              state: details?.state,
              postalCode: details?.postal_code,
              country: details?.country,
            };
            addresses.push(address);
          }
        }
      });

      const remitAddress = activeSubscription?.addresses?.find(
        (a) => a?.isRemittance,
      ) || {
        company: gateway?.contactCompany,
        isRemittance: true,
        ...(gateway?.addresses.find((a) => a.isInvoice)?.addressModel ||
          gateway?.addresses.find((a) => a.isPrimary)?.addressModel),
      };

      addresses.push(remitAddress);

      return addresses;
    };

    const formatLineItems = (items) => {
      return items.map((item) => ({
        ...item,
        unitPrice: Dinero({ amount: item.unitPrice }).toUnit(),
        discount: Dinero({ amount: item.discount }).toUnit(),
        shippedAmount: item.advancedFieldsEnabled ? item.shippedAmount : null,
        freightAmount: item.advancedFieldsEnabled
          ? Dinero({
              amount: item.freightAmount || 0,
            }).toUnit()
          : null,
        unitOfMeasureId: item.advancedFieldsEnabled
          ? item.unitOfMeasureId
          : null,
        localTaxPercent: item.isTaxable
          ? Dinero({
              amount: item.localTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
        nationalTaxPercent: item.isTaxable
          ? Dinero({
              amount: item.nationalTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
      }));
    };

    const formatPayload = () => {
      const sub = structuredClone(methods.getValues());

      sub.customerId = customer?.customerId;
      sub.addresses = sub.billing ? formatAddresses(sub) : null;

      sub.subscriptionStatusId = SUBSCRIPTION_STATUS_IDS.DRAFT;

      if (
        sub.recurrence.invoiceLengthInDays === -1 ||
        !sub.recurrence.invoiceLengthInDays
      ) {
        sub.recurrence.invoiceLengthInDays =
          sub.recurrence.paymentDue ||
          activeSubscription?.recurrence?.invoiceLengthInDays;
      }

      if (!sub.paymentMethod) sub.paymentMethod = {};

      checkSingleProcessors(sub);
      sub.paymentMethod.isAutoCharged = isAutoCharged;
      sub.paymentMethod.customerPaymentMethodId = isAutoCharged
        ? customerPaymentMethod?.paymentMethodId
        : null;

      sub.lineItems = formatLineItems(sub.lineItems);

      if (!sub.recurrence.termEndDate?.length)
        sub.recurrence.termEndDate = null;
      else sub.recurrence.numberOfBillingPeriods = null;

      sub.recurrence.isAutoRenewed = sub.recurrence.isAutoRenewed === "true";

      return sub;
    };

    const checkSingleProcessors = (sub) => {
      if (!sub?.paymentMethod?.cardProcessorId) {
        const cardProcessors = processors.filter(
          (p) => p.processorType.type === "CreditCard" && !p.isDeleted,
        );

        sub.paymentMethod.cardProcessorId =
          cardProcessors.find((p) => p.isDefaultCard)?.processorId ||
          cardProcessors[0]?.processorId;
      }
      if (!sub?.paymentMethod?.achProcessorId) {
        const achProcessors = processors.filter(
          (p) => p.processorType.type === "Ach" && !p.isDeleted,
        );
        sub.paymentMethod.achProcessorId =
          achProcessors.find(
            (p) =>
              p.isDefaultAch &&
              p.features?.availableSecCodes?.some(
                (s) => s.toLowerCase() === "web",
              ),
          )?.processorId || achProcessors[0]?.processorId;
      }
    };

    const handleSave = async (e) => {
      e.preventDefault();

      if (
        !methods.getValues("billing.state")?.trim().length ||
        !methods.getValues("billing.email")?.trim().length
      ) {
        setDisplayBilling(true);
        methods.trigger("billing");
        document.querySelector(".body-content").scrollTo(0, 0);

        return;
      }

      if (
        methods.getValues("recurrence.endSubscription") === "numPeriods" &&
        methods.getValues("recurrence.subscriptionEnds") === "endDate" &&
        !methods.getValues("recurrence.termEndDate")?.length
      ) {
        methods.setError("recurrence.termEndDate", {
          type: "required",
          message: "End Date is required",
        });
        document
          .getElementsByName("recurrence.termEndDate")?.[0]
          ?.scrollIntoViewIfNeeded();

        return;
      }

      methods.trigger().then((valid) => {
        if (valid) {
          if (!customerPaymentMethod && isAutoCharged) {
            methods.setError("paymentMethod.customerPaymentMethodId", {
              type: "required",
              message: "A Payment Method is required for automatic payments.",
            });

            return;
          }

          methods.clearErrors("paymentMethod.customerPaymentMethodId");
          methods.clearErrors("recurrence.termEndDate");
          const sub = formatPayload();

          if (e?.nativeEvent?.submitter?.name !== "draft-button") {
            sub.subscriptionStatusId = calculateStatus(sub);
          }

          onSave(sub);
        }
      });
    };

    const handleUpdate = (id) => {
      handleMenuClose();

      if (!customerPaymentMethod && isAutoCharged) {
        methods.setError("paymentMethod.customerPaymentMethodId", {
          type: "required",
          message: "A Payment Method is required for automatic payments.",
        });

        return;
      }

      methods.clearErrors("paymentMethod.customerPaymentMethodId");
      const sub = formatPayload();

      if (activeSubscription.status.name !== SUBSCRIPTION_STATUSES.DRAFT) {
        sub.lineItems = null;
        sub.addresses = null;
        sub.recurrence.termStartDate = null;
        sub.recurrence.billingStartDate = null;
        sub.prefix = activeSubscription.prefix;
      }

      if (id) sub.subscriptionStatusId = id;
      else sub.subscriptionStatusId = calculateStatus(sub);

      onUpdate(sub);
    };

    const handlePause = () => {
      setConfirmationDialogProps({
        alertTitle: "Do you wish to pause this subscription?",
        alertLevel: "warning",
        open: true,
        content:
          "Unpaid invoices will remain open and future invoices will not be generated until the Subscription is resumed.",
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            open: false,
          }),
        onConfirm: onPause,
        okButtonClass: "btn--primary__danger",
        okButtonText: "Pause Subscription",
        closeButtonClass: "btn--secondary__danger",
        closeButtonText: "Cancel",
      });
    };

    const handleResume = () => {
      setOpenResume(true);
    };

    const handleCancel = () => {
      setOpenCancel(true);
    };

    const handleDelete = () => {
      setConfirmationDialogProps({
        alertTitle: "Do you wish to delete this subscription?",
        alertLevel: "warning",
        open: true,
        content: "Once this subscription has been deleted it cannot be undone.",
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            open: false,
          }),
        onConfirm: onDelete,
        okButtonClass: "btn--primary__danger",
        okButtonText: "Delete Subscription",
        closeButtonClass: "btn--secondary__danger",
        closeButtonText: "Cancel",
      });
    };

    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSave} ref={ref}>
          <Grid className="subscription-details-container">
            {mode === "create" ? (
              <Typography variant="h2">Create Subscription</Typography>
            ) : (
              <Grid container justifyContent="space-between">
                <Grid item xs={8}>
                  <Typography variant="h2">
                    {activeSubscription?.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {statusTag(activeSubscription?.status?.name)}
                  {(canEditSubscription || canDeleteSubscription) && (
                    <IconButton
                      aria-label="options"
                      onClick={(e) => setOptionsAnchorEl(e.currentTarget)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container marginTop={1}>
              <Divider />
            </Grid>
            {mode === "view" && activeSubscription && (
              <Summary subscription={activeSubscription} />
            )}
            {
              <CustomerDetails
                mode={mode}
                subscription={activeSubscription}
                customer={customer}
                openCustomerDrawer={setSearchCustomer}
                billingAddress={billingAddress}
                shippingAddress={shippingAddress}
                loading={customerLoading}
                displayBilling={displayBilling}
                setDisplayBilling={setDisplayBilling}
                displayShipping={displayShipping}
                setDisplayShipping={setDisplayShipping}
              />
            }
            {mode === "create" && !customer && (
              <Grid marginY={2}>
                <Button
                  className="btn--primary"
                  fullWidth
                  startIcon={<AccountCircleOutlinedIcon />}
                  endIcon={
                    customerLoading ? <CircularProgress size={15} /> : null
                  }
                  onClick={() => setSearchCustomer(true)}
                  disabled={customerLoading}
                >
                  Select a Customer
                </Button>
              </Grid>
            )}
            {mode !== "view" && (
              <SetUp mode={mode} subscription={activeSubscription} />
            )}
            {mode !== "view" && (
              <BillingSchedule
                mode={mode}
                subscription={activeSubscription}
                billingPeriods={billingPeriods}
              />
            )}
            {!unitsLoading && (
              <Grid container marginTop={2}>
                <Grid item marginY={2}>
                  <Typography variant="h3">
                    <strong>Line Items</strong>
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className="subscription-details"
                  rowSpacing={2}
                  columnSpacing={1}
                >
                  <LineItemTotals
                    v2
                    object={
                      mode !== "create"
                        ? activeSubscription
                        : {
                            ...methods.getValues(),
                            addresses: [
                              {
                                isShipping: true,
                                state: methods.getValues("shipping")?.state,
                              },
                            ],
                          }
                    }
                    defaultLineItem={defaultLineItem}
                    defaultFees={defaultTotals}
                    totalSuffix={` / ${billingPeriodName}`}
                    unitsOfMeasure={unitsOfMeasure}
                    mode={
                      mode === "edit" &&
                      activeSubscription?.status.name !==
                        SUBSCRIPTION_STATUSES.DRAFT
                        ? "view"
                        : mode
                    }
                  />
                </Grid>
              </Grid>
            )}
            {mode === "view" && activeSubscription && (
              <InvoiceTable
                loading={invoiceLoading}
                subscription={activeSubscription}
                onViewAsPDF={handleViewAsPDF}
              />
            )}
            {mode !== "view" && !processorsLoading ? (
              <PaymentDetails
                mode={mode}
                subscription={activeSubscription}
                customer={customer}
                processors={processors}
                loading={customerPaymentMethodLoading}
                isAutoCharged={isAutoCharged}
                setIsAutoCharged={setIsAutoCharged}
                customerPaymentMethod={customerPaymentMethod}
                setCustomerPaymentMethod={setCustomerPaymentMethod}
                onCreateCustomerPaymentMethod={handleCreatePaymentMethod}
                showErrorMessage={showErrorMessage}
              />
            ) : null}
            {mode === "create" && (
              <Grid container marginTop={2} justifyContent="flex-end">
                <Tooltip
                  placement="top-start"
                  title={
                    customer
                      ? ""
                      : "Please add a customer to save the subscription"
                  }
                >
                  <span>
                    <Button
                      className="btn--secondary"
                      disabled={!customer || submitting}
                      name="draft-button"
                      type="submit"
                      endIcon={
                        submitting ? <CircularProgress size={15} /> : null
                      }
                    >
                      Save as Draft
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  placement="top-start"
                  title={
                    customer
                      ? ""
                      : "Please add a customer to create the subscription"
                  }
                >
                  <span>
                    <Button
                      className="btn--primary"
                      id="save-button"
                      disabled={!customer || submitting}
                      onClick={handleSave}
                      endIcon={
                        submitting ? <CircularProgress size={15} /> : null
                      }
                    >
                      Create Subscription
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
            {mode === "edit" && (
              <Grid container marginTop={2} justifyContent="flex-end">
                <Button
                  className="btn--secondary"
                  onClick={() => setMode("view")}
                >
                  Cancel
                </Button>
                {activeSubscription?.status?.name !==
                SUBSCRIPTION_STATUSES.DRAFT ? (
                  <Button
                    className="btn--primary"
                    id="save-button"
                    onClick={() => handleUpdate()}
                    disabled={submitting}
                    endIcon={submitting ? <CircularProgress size={15} /> : null}
                  >
                    Update Subscription
                  </Button>
                ) : (
                  <Button
                    className="btn--primary"
                    id="save-button"
                    onClick={(e) => setSaveOptionsAnchorEl(e.currentTarget)}
                    disabled={submitting}
                    endIcon={
                      submitting ? (
                        <CircularProgress size={15} />
                      ) : (
                        <ArrowDropDownOutlinedIcon />
                      )
                    }
                  >
                    Update
                  </Button>
                )}
              </Grid>
            )}
            <SelectCustomerDrawer
              showCreateCustomer
              open={searchCustomer}
              setOpenDrawer={setSearchCustomer}
              onCustomerSelect={handleCustomerChange}
              onCreateCustomer={handleCreateCustomer}
            />
            <Menu
              id="positioned-menu"
              aria-labelledby="positioned-button"
              className="action-menu"
              anchorEl={optionsAnchorEl}
              open={optionsOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {canEditSubscription && mode !== "edit" && (
                <MenuItem onClick={toggleEdit}>Edit Subscription</MenuItem>
              )}
              {activeSubscription?.status?.name ===
                SUBSCRIPTION_STATUSES.ACTIVE &&
                canEditSubscription && (
                  <MenuItem onClick={handlePause}>Pause Subscription</MenuItem>
                )}
              {activeSubscription?.status?.name ===
                SUBSCRIPTION_STATUSES.PAUSED &&
                canEditSubscription && (
                  <MenuItem onClick={handleResume}>
                    Resume Subscription
                  </MenuItem>
                )}
              {(activeSubscription?.status?.name ===
                SUBSCRIPTION_STATUSES.ACTIVE ||
                activeSubscription?.status?.name ===
                  SUBSCRIPTION_STATUSES.OVERDUE ||
                activeSubscription?.status?.name ===
                  SUBSCRIPTION_STATUSES.PAUSED ||
                activeSubscription?.status?.name ===
                  SUBSCRIPTION_STATUSES.TRIALING) &&
                canEditSubscription && (
                  <MenuItem onClick={handleCancel}>
                    Cancel Subscription
                  </MenuItem>
                )}
              {canEditSubscription && canDeleteSubscription && <Divider />}
              {canDeleteSubscription && (
                <MenuItem
                  id="delete-subscription-button"
                  onClick={handleDelete}
                >
                  Delete Subscription
                </MenuItem>
              )}
            </Menu>
            <Menu
              id="positioned-menu"
              aria-labelledby="positioned-button"
              className="action-menu"
              anchorEl={saveOptionsAnchorEl}
              open={saveOptionsOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={() => handleUpdate(SUBSCRIPTION_STATUS_IDS.DRAFT)}
              >
                Update and Save as Draft
              </MenuItem>
              <MenuItem onClick={() => handleUpdate()}>
                Update and Create Subscription
              </MenuItem>
            </Menu>
            <ConfirmationDialog {...confirmationDialogProps} />
            <CancelDialog
              subscription={activeSubscription}
              open={openCancel}
              onConfirm={onCancel}
              onClose={() => setOpenCancel(false)}
            />
            <ResumeDialog
              subscription={activeSubscription}
              open={openResume}
              onConfirm={onResume}
              onClose={() => setOpenResume(false)}
            />
            <PreventNavigationDialog
              when={!submitting && methods.formState.isDirty}
              navigate={history.push}
              shouldBlockNavigation={(nextLocation) =>
                !submitting &&
                nextLocation.pathname !== "/error" &&
                nextLocation.pathname !== noProcessors &&
                nextLocation.pathname !== merchantRoutes.customer.create &&
                methods.formState.isDirty
              }
            />
          </Grid>
        </form>
      </FormProvider>
    );
  },
);

SubscriptionDetailsContainer.propTypes = {
  mode: PropTypes.oneOf(["view", "edit", "create"]),
  billingPeriods: PropTypes.arrayOf(PropTypes.object),
  activeSubscription: PropTypes.object,
  setSubscription: PropTypes.func,
  setMode: PropTypes.func,
  setFormIsDirty: PropTypes.func,
  showErrorMessage: PropTypes.func,
  submitting: PropTypes.bool,
  onSave: PropTypes.func,
  onUpdate: PropTypes.func,
  onPause: PropTypes.func,
  onResume: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

SubscriptionDetailsContainer.displayName = "SubscriptionDetailsContainer";

export default SubscriptionDetailsContainer;
