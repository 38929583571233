import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../../ReactHookForm/Select";
import { validationRules } from "../../../../constants/validationRules";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { regexPatterns } from "../../../../constants/regexPatterns";
import { FormProvider, useForm } from "react-hook-form";
import {
  USER_ROLES,
  GATEWAY_USER_ROLE_CODES,
  GATEWAY_USER_PERMISSION_CODES,
  iqProVer,
} from "../../../../constants/global";
import PhoneNumber from "../../../Contact/PhoneNumber";
import Switch from "../../../ReactHookForm/Switch";
import Button from "@mui/material/Button";
import ".././manageUsers.scss";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import { merchantRoutes } from "../../../../constants/routes";
import { stringFormat } from "../../../../utils/stringHelpers";
import { sassEndpoints } from "../../../../constants/endpoints";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { TokenizerConfigContext } from "../../../../contexts/TokenizerConfigContext";
function ManageUserCreate({ createGatewayUser, permissions, roles }) {
  const { ...methods } = useForm();
  const theme = useTheme();
  const history = useHistory();
  const { userSettings } = useContext(UserSettingsContext);
  const [isLookUp, setIsLookUp] = useState(true);
  const [foundUser, setFoundUser] = useState(null);
  const [newEmail, setNewEmail] = useState(null);
  const [namesHidden, setNamesHidden] = useState(false);
  const [currentRole, setCurrentRole] = useState(
    GATEWAY_USER_ROLE_CODES.standard,
  );
  const [userError, setUserError] = useState("");
  const location = useLocation();
  const copiedSettings = location?.state?.copiedPermissions || null;
  const tokenConfig = useContext(TokenizerConfigContext);

  useEffect(() => {
    if (!isLookUp && foundUser) {
      methods.setValue("email", foundUser.email);
    } else if (!isLookUp && newEmail) {
      methods.setValue("email", newEmail);
    }
  }, [isLookUp]);

  const roleChanged = () => {
    setCurrentRole(methods.getValues().role);
  };

  const standardPermissions = [
    "MANAGE_USERS",
    "VIEW_SETTLEMENT_BATCHES",
    "PROCESS_CREDIT",
    "VIEW_OTHERS_TRANSACTIONS",
    "DELETE_INVOICE",
    "VIEW_ALL_INVOICES",
  ];

  const handleDefaultPermissions = (permission) => {
    if (copiedSettings) {
      return togglePermissions(permission);
    } else if (currentRole === GATEWAY_USER_ROLE_CODES.admin) {
      return true;
    } else if (standardPermissions.includes(permission.code)) {
      return false;
    }
    return true;
  };

  const createUser = () => {
    const payload = assemblePayload();
    createGatewayUser(payload);
  };

  const handlePermissions = (permissionsList) => {
    let permissionCodes = [];
    Object.keys(permissionsList).forEach((name) => {
      if (permissionsList[name]) {
        let foundP = permissions.find((p) => {
          return p.code.toLowerCase() === name;
        });
        if (foundP) {
          permissionCodes.push(foundP.permissionId);
        }
      }
    });
    return permissionCodes;
  };

  const assemblePayload = () => {
    const newUserData = {
      firstName: methods.getValues().first_name || "NA",
      lastName: methods.getValues().last_name || "NA",
      email: foundUser ? foundUser.email : newEmail,
      roleId: methods.getValues().role,
      phone: methods.getValues().phone,
      permissionIds: copiedSettings?.permissions
        ? handlePermissions(methods.getValues().permissions)
        : methods.getValues().role === GATEWAY_USER_ROLE_CODES.standard
          ? permissions
              .filter((p) =>
                GATEWAY_USER_PERMISSION_CODES.standard.includes(p.code),
              )
              .map((obj) => obj.permissionId)
          : permissions
              .filter((p) =>
                GATEWAY_USER_PERMISSION_CODES.admin.includes(p.code),
              )
              .map((obj) => obj.permissionId),
    };
    return newUserData;
  };

  const togglePermissions = (property) => {
    return copiedSettings.permissions.find((p) => {
      {
        return p.code === property.code;
      }
    });
  };

  const lookupApi = () => {
    setUserError(null);
    axios
      .post(
        stringFormat(sassEndpoints.gatewayUsers.validate, [
          userSettings.gatewayId,
        ]),
        { email: methods.getValues().email_lookup },
      )
      .then((response) => {
        if (!response.data.data.isCreateAllowed) {
          setUserError("User already exists on Gateway.");
        } else if (response.data.data.accountExists) {
          const user = {
            email: methods.getValues().email_lookup,
          };
          setFoundUser(user);
          setIsLookUp(false);
          setNamesHidden(true);
        } else {
          setNewEmail(methods.getValues().email_lookup);
          setIsLookUp(false);
        }
      })
      .catch(() => {
        setUserError("Failed to lookup email");
        console.log("failed to lookup email");
      });
  };

  const pageReturn = () => {
    history.push(merchantRoutes.manage.users);
  };

  return (
    <div className="create-detail-wrapper">
      <FormProvider {...methods}>
        <div className="email-lookup-container">
          <div className="email-lookup-wrapper">
            <h2>User Info</h2>
            <div className="lookup-form">
              <RegisteredTextField
                id="email_lookup"
                name={"email_lookup"}
                label="Email"
                disabled={isLookUp ? false : true}
                fullWidth
                errors={methods.errors}
                rules={{
                  pattern: validationRules.email,
                  maxLength: validationRules.maxLength100,
                  required: true,
                }}
              />
              {isLookUp && (
                <div>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => lookupApi()}
                    sx={{ marginLeft: "10px" }}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
            {userError && (
              <>
                <p
                  style={{
                    fontWeight: "bold",
                    color: theme?.palette?.mode === "dark" ? "#fff" : "#D32F2F",
                  }}
                >
                  {userError}
                </p>
              </>
            )}
            {isLookUp && userError && (
              <Button
                size="small"
                variant="contained"
                color="neutrals"
                onClick={() => pageReturn()}
                sx={{ marginTop: "15px" }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
        {!isLookUp && (
          <div className="create-details-body">
            <div className="form-elements">
              <Grid container>
                {!namesHidden && (
                  <>
                    <Grid
                      sx={{ marginBottom: "10px", marginRight: { sm: "10px" } }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <RegisteredTextField
                        id="first_name"
                        name={"first_name"}
                        label="First Name"
                        fullWidth
                        errors={methods.errors}
                        rules={{
                          pattern: regexPatterns.name,
                          required: true,
                          maxLength: validationRules.maxLength100,
                        }}
                      />
                    </Grid>
                    <Grid
                      sx={{ marginBottom: "10px", marginRight: { sm: "10px" } }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <RegisteredTextField
                        id="last_name"
                        name={"last_name"}
                        label="Last Name"
                        errors={methods.errors}
                        fullWidth
                        rules={{
                          pattern: regexPatterns.name,
                          required: true,
                          maxLength: validationRules.maxLength100,
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  sx={{ marginBottom: "10px" }}
                  xs={12}
                  sm={!namesHidden ? 4 : 6}
                >
                  <PhoneNumber
                    name="phone"
                    id="phone"
                    label="Phone"
                    required={true}
                    viewOnly={false}
                  />
                </Grid>
              </Grid>

              {roles && (
                <Grid item xs={12} sm={6}>
                  <Select
                    control={methods.control}
                    name="role"
                    fullWidth
                    id="role"
                    onChange={roleChanged}
                    defaultValue={
                      copiedSettings?.role ||
                      roles.find((x) => x.code === USER_ROLES.GATEWAY_ADMIN)
                        ?.roleId
                    }
                    label="Role"
                  >
                    {roles
                      .filter(
                        (role) =>
                          role.code === USER_ROLES.GATEWAY_STANDARD ||
                          role.code === USER_ROLES.GATEWAY_ADMIN,
                      )
                      .map((role, i) => {
                        return (
                          <MenuItem key={i} value={role.roleId}>
                            {role.code === USER_ROLES.GATEWAY_STANDARD
                              ? "Standard"
                              : "Admin"}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              )}
            </div>
            <div className="create-permissions-wrapper">
              {currentRole === GATEWAY_USER_ROLE_CODES.admin ? (
                <>
                  {" "}
                  <h3>Permissions</h3>
                  <div className="toggle-container">
                    {permissions && (
                      <>
                        <ul>
                          {" "}
                          {permissions.map((property, i) => {
                            if (
                              property?.code?.toLowerCase() !== "vault_read" &&
                              property?.code?.toLowerCase() !==
                                "manage_users" &&
                              property?.code?.toLowerCase().slice(0, 3) !==
                                "pro" &&
                              !(
                                (property?.code?.toLowerCase() ===
                                  "manage_cust_payable_token" ||
                                  property?.code?.toLowerCase() ===
                                    "view_cust_payable_token") &&
                                (tokenConfig.achTokenizer === iqProVer.v1 ||
                                  tokenConfig.cardTokenizer === iqProVer.v1)
                              )
                            ) {
                              return (
                                <Tooltip
                                  key={i}
                                  title={
                                    property?.code?.toLowerCase() ===
                                    "view_others_transactions"
                                      ? "Turn this off to restrict user view to only their transactions"
                                      : ""
                                  }
                                >
                                  <li>
                                    <Switch
                                      key={i}
                                      control={methods.control}
                                      label={property.description}
                                      labelPlacement="start"
                                      name={
                                        "permissions." +
                                        property?.code?.toLowerCase()
                                      }
                                      disabled={copiedSettings ? false : true}
                                      defaultValue={handleDefaultPermissions(
                                        property,
                                      )}
                                    />
                                  </li>
                                </Tooltip>
                              );
                            }
                          })}
                        </ul>
                        <ul>
                          {permissions.map((property, i) => {
                            if (
                              property?.code?.toLowerCase().slice(0, 3) ===
                              "pro"
                            ) {
                              return (
                                <li key={i}>
                                  <Switch
                                    control={methods.control}
                                    label={property.description}
                                    labelPlacement="start"
                                    name={
                                      "permissions." +
                                      property?.code?.toLowerCase()
                                    }
                                    disabled={copiedSettings ? false : true}
                                    defaultValue={handleDefaultPermissions(
                                      property,
                                    )}
                                  />
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <h3>Permissions</h3>
                  <div className="toggle-container">
                    {permissions && (
                      <>
                        <ul>
                          {" "}
                          {permissions.map((property, i) => {
                            if (
                              property?.code?.toLowerCase() !== "vault_read" &&
                              property?.code?.toLowerCase() !==
                                "manage_users" &&
                              property?.code?.toLowerCase().slice(0, 3) !==
                                "pro" &&
                              !(
                                (property?.code?.toLowerCase() ===
                                  "manage_cust_payable_token" ||
                                  property?.code?.toLowerCase() ===
                                    "view_cust_payable_token") &&
                                (tokenConfig.achTokenizer === iqProVer.v1 ||
                                  tokenConfig.cardTokenizer === iqProVer.v1)
                              )
                            ) {
                              return (
                                <Tooltip
                                  key={i}
                                  title={
                                    property?.code?.toLowerCase() ===
                                    "view_others_transactions"
                                      ? "Turn this off to restrict user view to only their transactions"
                                      : ""
                                  }
                                >
                                  <li>
                                    <Switch
                                      key={i}
                                      control={methods.control}
                                      label={property.description}
                                      labelPlacement="start"
                                      name={
                                        "permissions." +
                                        property?.code?.toLowerCase()
                                      }
                                      disabled={copiedSettings ? false : true}
                                      defaultValue={handleDefaultPermissions(
                                        property,
                                      )}
                                    />
                                  </li>
                                </Tooltip>
                              );
                            }
                          })}
                        </ul>
                        <ul>
                          {permissions.map((property, i) => {
                            if (
                              property?.code?.toLowerCase().slice(0, 3) ===
                              "pro"
                            ) {
                              return (
                                <li key={i}>
                                  <Switch
                                    control={methods.control}
                                    label={property.description}
                                    labelPlacement="start"
                                    name={
                                      "permissions." +
                                      property?.code?.toLowerCase()
                                    }
                                    disabled={copiedSettings ? false : true}
                                    defaultValue={handleDefaultPermissions(
                                      property,
                                    )}
                                  />
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="btn-row">
              <Button
                size="small"
                variant="contained"
                color="neutrals"
                onClick={() => {
                  methods.reset();
                  setFoundUser(null);
                  setIsLookUp(true);
                  setNamesHidden(false);
                }}
                sx={{ marginRight: "15px" }}
              >
                Cancel
              </Button>
              <Button
                size="small"
                disabled={!methods.formState.isValid}
                variant="contained"
                color="primary"
                onClick={() => createUser()}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </FormProvider>
    </div>
  );
}
ManageUserCreate.propTypes = {
  createGatewayUser: PropTypes.func,
  permissions: PropTypes.array,
  roles: PropTypes.array,
};
export default ManageUserCreate;
